var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-header" }, [
    _c("div", { staticClass: "header", class: { "header-w": _vm.isScroll } }, [
      _c("div", { staticClass: "d-md-block d-none header-content-wrap" }, [
        _c("div", { staticClass: "header-content" }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("./img/logo.png") },
            on: {
              click: function ($event) {
                return _vm.$router.push({ name: "borrow" })
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "menu-area" }, [
            _c(
              "div",
              { staticClass: "menu-tab" },
              _vm._l(_vm.menuList, function (menu, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "btn-tab",
                    class: { "btn-tab-active": menu.name === _vm.currentTab },
                  },
                  [
                    _c("router-link", { attrs: { to: { name: menu.name } } }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.$t(menu.name).title) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "line",
                      class: {
                        "line-full": menu.name === _vm.currentTab,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-block d-md-none cm-header-mobile-wrap" }, [
        _c("div", { staticClass: "cm-header-mobile" }, [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("./img/logo.png") },
            on: {
              click: function ($event) {
                return _vm.$router.push({ name: "borrow" })
              },
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "extend",
            attrs: { src: require("./img/extend.svg") },
            on: { click: _vm.onExtend },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "extend-box", class: { "is-extend": _vm.isExtend } },
            [
              _c("div", { staticClass: "item-title" }, [
                _c("img", {
                  staticClass: "logo",
                  attrs: { src: require("./img/logo.png") },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "extend",
                  attrs: { src: require("./img/union.svg") },
                  on: { click: _vm.onExtend },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-wrap" },
                _vm._l(_vm.menuList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.name,
                      staticClass: "item",
                      class: {
                        "nav-active": _vm.$route.name === item.name,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onChange(item.name)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "left" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.$t(item.name).title) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "right" }),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }