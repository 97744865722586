<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-12-25 14:25:21 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2023-12-25 17:08:33 -->

<template>
    <div class="pa-header">
        <div
            class="header"
            :class="{'header-w': isScroll}"
        >
            <div class="d-md-block d-none header-content-wrap">
                <div class="header-content">
                    <img
                        src="./img/logo.png"
                        class="logo"
                        @click="$router.push({name: 'borrow'})"
                    >
                    <div class="menu-area">
                        <div class="menu-tab">
                            <div
                                v-for="(menu, index) in menuList"
                                :key="index"
                                class="btn-tab"
                                :class="{'btn-tab-active': menu.name === currentTab}"
                            >
                                <router-link :to="{name: menu.name}">
                                    {{ $t(menu.name).title }}
                                </router-link>
                                <div
                                    class="line"
                                    :class="{
                                        'line-full': menu.name === currentTab
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- h5 -->
            <div class="d-block d-md-none cm-header-mobile-wrap">
                <div class="cm-header-mobile">
                    <img
                        src="./img/logo.png"
                        class="logo"
                        @click="$router.push({name: 'borrow'})"
                    >
                    <img
                        src="./img/extend.svg"
                        class="extend"
                        @click="onExtend"
                    >

                    <div
                        class="extend-box"
                        :class="{'is-extend': isExtend}"
                    >
                        <div class="item-title">
                            <img
                                src="./img/logo.png"
                                class="logo"
                            >
                            <img
                                src="./img/union.svg"
                                class="extend"
                                @click="onExtend"
                            >
                        </div>

                        <div class="item-wrap">
                            <div
                                v-for="item in menuList"
                                :key="item.name"
                                class="item"
                                :class="{
                                    'nav-active': $route.name === item.name
                                }"
                                @click="onChange(item.name)"
                            >
                                <div class="left">
                                    {{ $t(item.name).title }}
                                </div>
                                <div class="right" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import {routeMap} from 'mabilis-cash/common/router/routes';

export default {
    name: 'PaHeader',

    data() {
        return {
            routeMap,
            menuList: [routeMap.borrow, routeMap.faq, routeMap.blogs],
            isExtend: false,
            isScroll: false,
        };
    },

    computed: {
        currentTab() {
            return this.$route.name;
        }
    },

    mounted() {
        this.isScroll = window.scrollY > 0;
        window.addEventListener('scroll', () => {
            this.isScroll = window.scrollY > 0;
        });
    },

    beforeDestroy() {
        window.removeEventListener('scroll', () => {
            this.isScroll = window.scrollY > 0;
        });
    },

    methods: {
        onExtend() {
            this.isExtend = !this.isExtend;
        },

        onChange(routeName) {
            this.$router.push({name: routeName});
            this.onExtend();
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "~mabilis-cash/common/style/index";

.pa-header {
    position: sticky;
    top: 0;
    max-width: 100vw;
    z-index: 90;

    .header {
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 80px;
        overflow: hidden;

        @media screen and (max-width: $screen-md) {
            height: 50px;
            box-shadow: none;
        }

        &-w {
            background-color: #fff;
            box-shadow: 0 15px 30px 0 rgba(232, 239, 245, 0.7);
        }

        &-content-wrap {
            width: 100%;
        }

        &-content {
            width: 100%;
            max-width: 1440px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo {
                padding-left: 100px;
                width: auto;
                height: 36px;
                cursor: pointer;
            }

            .menu-area {
                position: relative;
                padding-right: 100px;

                .menu-tab {
                    display: flex;
                    justify-content: flex-end;
                    white-space: nowrap;
                }

                .btn-tab {
                    margin-left: 60px;
                    position: relative;

                    a {
                        color: #151245;
                        font-family: Mulish;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                    }

                    &-active {
                        a {
                            font-weight: 700;
                        }
                    }

                    .line {
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        bottom: -4px;
                        background: #151245;
                        width: 0;
                        height: 3px;
                        transition: all 0.5s ease;
                    }

                    .line-full {
                        width: 100%;
                    }

                    &:hover {
                        .line {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.cm-header-mobile {
    position: fixed;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 999;

    .logo {
        width: auto;
        height: 24px;
    }

    .extend {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 20px;
    }

    .extend-box {
        transition: all 0.5s ease;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        height: 0;
        overflow: hidden;
        width: 100%;

        .item-title {
            display: flex;
            height: 50px;
            padding: 0 20px;
            justify-content: center;
            align-items: center;
            box-shadow: 0 5px 20px 0 #e8eff5;

            .extend {
                width: 15px;
                height: 15px;
                position: absolute;
                right: 20px;
                left: unset;
            }
        }

        .item-wrap {
            margin: 30px 0;

            .item {
                box-sizing: border-box;
                display: flex;
                height: 52px;
                padding: 0 20px;
                justify-content: space-between;
                align-items: center;

                &:last-child {
                    border-bottom: none;
                }

                .left {
                    color: #9393a3;
                    font-family: Lexend;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .right {
                    width: 20px;
                    height: 20px;
                    background-image: url("./img/arrow-r.svg");
                    background-size: cover;
                }
            }

            .nav-active {
                background: #f0f4f8;

                .left {
                    color: $base-black;
                    font-weight: 700;
                }

                .right {
                    background-image: url("./img/arrow-active-r.svg");
                }
            }
        }
    }

    .is-extend {
        height: 100vh;
        z-index: 999;
        background: #fff;
    }
}
</style>
